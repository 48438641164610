  // display the selected currency divs and hide the other currency ones
window.set_currency = function(currency) {
  if( currency === "local" ) {
    $("#local_currency_button").addClass("btn-outline-info disabled");
    $("#local_currency_button").removeClass("btn-primary");
    $("#dolar_currency_button").removeClass("btn-outline-info disabled");
    $("#dolar_currency_button").addClass("btn-primary");

    show_currency = ".local_currency";
    hide_currency = ".dolar_currency";

  } else {
    $("#local_currency_button").removeClass("btn-outline-info disabled");
    $("#local_currency_button").addClass("btn-primary");
    $("#dolar_currency_button").addClass("btn-outline-info disabled");
    $("#dolar_currency_button").removeClass("btn-primary");

    show_currency = ".dolar_currency";
    hide_currency = ".local_currency";
  }

  $( show_currency ).each( function() {
    $(this).removeClass("d-none");
  });

  $( hide_currency ).each( function() {
      $(this).addClass("d-none");
  });
}
